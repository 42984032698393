import util from './util';
import Accordion from 'accordion-js';
import 'accordion-js/dist/accordion.min.css';

window.onload = () => {
  setupVendorLibs();
  setupToTopBtn();
  setupMobileMenu();
  resize();
};

function addScrolled(event) {
  const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  if (scrollTop > 60) document.body.classList.add('scrolled');
  else document.body.classList.remove('scrolled');
}

 const resize = () => {
  util.width = window.innerWidth;
  util.height = window.innerHeight;
  if (util.isMobile()) {
    document.body.classList.add('mobile');
  } else {
    document.body.classList.remove('mobile');
  }
}

window.onresize = resize;
window.onscroll = addScrolled;


function setupToTopBtn() {
  const toTopBtn = document.getElementById('scroll-link');
  if (toTopBtn) {
    const text = toTopBtn.querySelector('span');
    window.addEventListener('scroll', function(e) {
      // http://stackoverflow.com/a/28633515/962603
      var scroll = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    });
    toTopBtn.addEventListener('click', () => {
      animateToTop(document.documentElement);
    })
  }
}

function animateToTop(el) {
  const scrollPerTick = 0.022 * el.scrollTop;
  let scrollToTop = window.setInterval(function() {
        let pos = el.scrollTop;
        if ( pos > 0 && el.scrollTop >= 20) {
          el.scrollTop = pos - scrollPerTick;
        } else {
          clearInterval(scrollToTop);
        }
      },
      9
  )
}

function setupMobileMenu() {
  const menuButton = document.getElementById('menu-button');
  const menuTitle = document.getElementById('mobile-menu-title');
  if (menuButton) {
    menuButton.addEventListener('click', () => {
      const navContainer = document.getElementById('nav-container');
      menuButton.classList.toggle('is-active');
      const isOpen = menuButton.classList.contains('is-active');
      if (navContainer) {
        navContainer.style.display = isOpen ? 'flex' : 'none';
        if (navContainer.style.display = isOpen) {
          document.body.classList.add('overflow-hidden');
          menuTitle.innerText = 'Close';
        } else {
          document.body.classList.remove('overflow-hidden');
            menuTitle.innerText = 'Menu';
        }
      }
    });
  }
}

function setupVendorLibs() {
    if ( document.querySelector('.accordion-container') ) {
        const accordions = Array.from(document.querySelectorAll('.accordion-container'));
        new Accordion( accordions, {
            duration: 300,
            ariaEnabled: true,
            showMultiple: true
        });
    }
}







